@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Condensed', sans-serif;
}

body {
  background-color: rgb(240,240,240);
}